import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: React.ReactNode;
  className?: string;
  outerClassName?: string;
};

export const Container = ({
  children,
  className = '',
  outerClassName = '',
}: Props) => {
  return (
    <div className={twMerge('w-full', outerClassName)}>
      <div
        className={twMerge(
          'mx-auto my-0 max-w-[1400px] w-full px-5 lg:px-5',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
