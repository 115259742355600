import { graphql, useStaticQuery } from 'gatsby';
import { Topic } from '@blogstreamai/autoblog-types';

export const useTopics = () => {
  const data = useStaticQuery(graphql`
    query {
      allTopic {
        edges {
          node {
            topic
            tagline
            slug
            highlightColor
          }
        }
      }
    }
  `);

  // @ts-ignore
  return data.allTopic.edges.map(({ node }) => node) as Topic[];
};
