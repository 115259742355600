import React, { PropsWithChildren } from 'react';
import { Header } from '../components/Global/Header';
import { Footer } from '../components/Global/Footer';
import { FullSignupCard } from '../components/Newsletter/FullSignupCard';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocation } from '@reach/router';

const queryClient = new QueryClient();

export default function MainLayout({ children }: PropsWithChildren) {
  const location = useLocation();

  if (location.pathname === '/') {
    return <>{children}</>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col w-full min-h-screen gap-5">
        <Header />
        <main className="flex-1 w-full">{children}</main>
        <FullSignupCard />
        <Footer />
      </div>
    </QueryClientProvider>
  );
}
