exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-categories-tsx": () => import("./../../../src/pages/blog/categories.tsx" /* webpackChunkName: "component---src-pages-blog-categories-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-author-single-tsx": () => import("./../../../src/templates/authorSingle.tsx" /* webpackChunkName: "component---src-templates-author-single-tsx" */),
  "component---src-templates-post-single-tsx": () => import("./../../../src/templates/postSingle.tsx" /* webpackChunkName: "component---src-templates-post-single-tsx" */),
  "component---src-templates-topic-single-tsx": () => import("./../../../src/templates/topicSingle.tsx" /* webpackChunkName: "component---src-templates-topic-single-tsx" */)
}

