import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export const HStack = ({
  children,
  className = '',
}: PropsWithChildren<Props>) => (
  <div className={twMerge('flex items-center w-full gap-5', className)}>
    {children}
  </div>
);
