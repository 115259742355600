import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const VStack = ({
  children,
  className = '',
  style = {},
}: PropsWithChildren<Props>) => (
  <div
    className={twMerge('flex flex-col justify-center w-full gap-5', className)}
    style={style}
  >
    {children}
  </div>
);
