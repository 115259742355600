import React from 'react';
import { Container } from '../Layout/Container';
import { HStack } from '../Layout/HStack';
import { Logo } from './Logo';
import { MainNavigation } from './MainNavigation';
import { Link } from 'gatsby';

export const Header = () => {
  return (
    <Container outerClassName="border-b border-gray-300">
      <header className="flex justify-between items-center ">
        <HStack className="gap-6">
          <Link
            to="/blog"
            className="py-5 h-[35px] box-content"
            aria-label="Go to Journeys home"
          >
            <Logo />
          </Link>
          <MainNavigation />
        </HStack>
      </header>
    </Container>
  );
};
