import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby';

type ItemProps = {
  to: string;
};
const NavItem = ({ children, to }: PropsWithChildren<ItemProps>) => (
  <Link to={to} className="hover:bg-amber-100 p-6 transition-all">
    {children}
  </Link>
);

export const MainNavigation = () => (
  <nav className="flex items-center gap-5">
    <NavItem to="/blog/categories">Categories</NavItem>
  </nav>
);
