import React from 'react';

type Props = {
  dark?: boolean;
};

export const Logo = ({ dark = false }: Props) => (
  <svg height="100%" fill="none" viewBox="0 0 1999 567">
    <path
      fill={dark ? '#fff' : '#000'}
      fillRule="evenodd"
      d="M318 434c67.262 0 124.861-36.091 148.732-87.283C472.395 379.044 496.451 432.5 611 432.5c117 0 138.833-72.333 139-108.5V162h-91.5l.5 127c.333 23.5-.5 70.5-51 70.5s-52-47-52-70.5V162h-91v.5l-.231 74.755C439.809 188.208 383.484 154 318 154c-69.498 0-128.679 38.53-151 92.437V162.5H76v296c-5.2 34.8-34.167 26.5-48 18l-28 74c132 51.2 166.333-32.667 167-81V341.563C189.321 395.47 248.502 434 318 434Zm62.5-140.5c0 34.518-27.534 62.5-61.5 62.5s-61.5-27.982-61.5-62.5S285.034 231 319 231s61.5 27.982 61.5 62.5ZM758 162v264h91.5V283c0-38 29.4-71.8 101-35l14-28.553V426h92V271c0-25 19.1-45 53.5-45s46 20 46 45v155h92.5v-68.105c22.18 37.613 66.72 74.837 133.5 75.105 99.6.4 131.5-45.5 135-68.5l-65.5-33c-8.67 10-32.9 29.9-60.5 29.5-34.5-.5-60-22.5-60-54.5h191.5C1526 256 1502.8 155 1382 155c-75.28 0-115.12 38.054-134.37 79.681C1241.23 205.387 1211.31 155 1133 155c-32.5 0-51 0-76.5 34.5V162h-85.888c-31.861-11.586-82.73-13.832-121.112 38v-38H758Zm575.5 95c.83-14.5 12.1-43.4 50.5-43 38.4.4 49 28.833 49.5 43h-100Z"
      clipRule="evenodd"
    />
    <path
      fill={dark ? '#fff' : '#000'}
      d="m1474.5 162 125 222.5-91 174.5h105l207-397h-105L1653 297.5 1580 162h-105.5Z"
    />
    <path
      fill={dark ? '#fff' : '#000'}
      d="M1906.5 155c46 .4 77.83 14.167 88 21l-30.5 58.5c-72.5-28.5-83.5-12-86 0-2 9.6 2 17.5 25.5 21 31.69 4.719 95.5 28.234 95.5 87.5 0 74-54.5 91-121.5 90-52.83-.789-91.67-22.833-104.5-35.5l37-61.5c62.5 47 92.5 28.5 92.5 12 0-11.271-15.67-18.24-27.42-23.46v-.001l-.01-.007-.02-.007c-2-.888-3.88-1.726-5.55-2.525l-1.28-.567c-27.35-12.145-78.58-34.891-76.22-82.933 3-61 57-84 114.5-83.5ZM178 54.5c0 30.1-24.4 54.5-54.5 54.5S69 84.6 69 54.5 93.4 0 123.5 0 178 24.4 178 54.5Z"
    />
  </svg>
);
